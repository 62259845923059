import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "../styles/GlobalStyle";
import { AnimatePresence } from "framer-motion";
import { FaWhatsapp } from "react-icons/fa";

import { Helmet } from "react-helmet";

import "../styles/reset.css";
import Cookies from "./Cookies";
import Footer from "./Footer";
import Navbar from "./Navbar";

export const Content = styled.section`
  overflow-x: hidden;
  padding-top: 128px;
  margin-top: -128px;
`;
const WhatsAppContainer = styled.div`
  position: fixed;
  left: 10px;
  bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.5rem;
  z-index: 5;
`;

const WhatsAppButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.verdeWpp};
  color: ${(p) => p.theme.bgGray};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const PopUp = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%) translateX(-10px);
  background-color: ${(p) => p.theme.bgGray};
  color: ${(p) => p.theme.textStrongGray};
  padding: 10px;
  border-radius: 8px;
  box-shadow: 2px 4px 8px ${(p) => p.theme.flat};
  font-size: 13px;
  width: 255px;
  line-height: 15px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(-50%)
    translateX(${({ isVisible }) => (isVisible ? "0" : "-10px")});
  transition: all 0.3s ease;
`;

export default function Layout({ children, taxdashs, comprar }) {
  const [cookies, setCookies] = useState(false);

  useEffect(() => {
    setCookies(localStorage.getItem("cookies") === "true");
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookies", true);
    setCookies(true);
  };

  const rejectCookies = () => {
    localStorage.setItem("cookies", false);
    setCookies(true);
  };

  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=551150267387&text&type=phone_number&app_absent=0";

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="icon" href="/taxcel-icon.ico" />{" "}
        {/*------- jivochat ---------*/}
        {/* <script
          type="text/javascript"
          src="//code.jivosite.com/widget/1fy7GwU0IC"
          async
        ></script>
        <script type="text/javascript">
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:372985,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script> */}
        {/* ---------------------- */}
        {/*------- hotjar ---------*/}
        <script>
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2619666,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
        {/* ---------------------- */}
        {/* <!-- Start of HubSpot Embed Code --> */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/46590252.js"
        ></script>
        {/* <!-- End of HubSpot Embed Code --> */}
        {/* <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-959950554"
          type="text/javascript"
        >
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'AW-959950554');
            `}
        </script> */}
      </Helmet>
      <WhatsAppContainer>
        <WhatsAppButton href={whatsappLink} target="_blank">
          <FaWhatsapp />
        </WhatsAppButton>
        <PopUp isVisible={isVisible}>
          Transforme sua gestão fiscal agora! 🌟 Vamos conversar no WhatsApp!
        </PopUp>
      </WhatsAppContainer>
      <GlobalStyle />
      <Navbar taxdashs={taxdashs} comprar={comprar} />
      <Content>{children}</Content>
      <Footer taxdashs={taxdashs} />
      <AnimatePresence>
        {!cookies && (
          <Cookies
            rejectCookies={rejectCookies}
            acceptCookies={acceptCookies}
          />
        )}
      </AnimatePresence>
    </ThemeProvider>
  );
}
